<script lang="ts">
import { useIntersectionObserver, useIntervalFn } from '@vueuse/core'
import type { UseInfiniteQueryReturnType } from '@tanstack/vue-query'
import emptyImg from '~/assets/imgs/empty-s1.svg'

function createTrigger(containerEle: Ref<HTMLElement | undefined>, callback: Function) {
  if (import.meta.env.SSR)
    return

  const triggerEl = document.createElement('div')

  Object.assign(triggerEl.style, {
    position: 'relative',
    zIndex: '5',
    marginTop: '-40vh',
    marginBottom: '40vh',
    width: '5px',
    height: '5px',
    // background: 'red',
  } satisfies Partial<CSSStyleDeclaration>)

  useIntervalFn(() => {
    const { transform } = triggerEl.style

    if (transform)
      triggerEl.style.removeProperty('transform')
    else triggerEl.style.setProperty('transform', 'translateX(-200%)')
  }, 250)

  const triggerOb = useIntersectionObserver(triggerEl, ([entry]) => {
    if (entry.isIntersecting)
      callback()
  })

  watch(containerEle, (ele) => {
    if (ele)
      ele.append(triggerEl)
  }, { immediate: true })

  onUnmounted(() => {
    triggerOb.stop()
    triggerEl.remove()
  })
}
</script>

<script setup lang="ts" generic="T extends UseInfiniteQueryReturnType<any, any>">
type TPage = T extends UseInfiniteQueryReturnType<infer P, any> ? NonNullable<P> : any
type TRecord = TPage extends { list: Array<infer I> } ? I : any

const props = defineProps<{
  queryier: T
}>()
const { queryier } = props
const $container = ref(import.meta.env.SSR ? undefined : document.querySelector('#__nuxt') as HTMLElement)
const pages = computed(() => queryier.data.value?.pages)

const isEmpty = computed(() => queryier.isSuccess.value && !pages.value?.[0].list?.length)
const isFetching = computed(() => queryier.isFetchingNextPage.value || queryier.isLoading.value)
const isLoadingError = computed(() => queryier.isError.value)
const noMore = computed(() => !queryier.hasNextPage?.value)

function fetchNextPage() {
  const { isFetching, isLoading, hasNextPage } = queryier

  if (isFetching.value || isLoading.value || !hasNextPage?.value)
    return

  queryier.fetchNextPage()
}

createTrigger($container, fetchNextPage)
</script>

<template>
  <div>
    <!-- 列表 -->
    <div class="list">
      <template v-for="(page, i) of pages" :key="i">
        <template v-for="record of page.list">
          <!-- eslint-disable-next-line vue/no-extra-parens -->
          <slot :record="(record as TRecord)" />
        </template>
      </template>
    </div>

    <!-- 空 -->
    <div v-if="isEmpty" class="mt-100px min-h-100px flex flex-col justify-center items-center">
      <img class="w-200px mb-15px" :src="emptyImg" :alt="$t('Q8smHu0l0nMmaFpE7_DHE')">
      <span class="text-#94a3b8">{{ $t('Q8smHu0l0nMmaFpE7_DHE') }}</span>
    </div>

    <!-- 加载更多 -->
    <div v-if="isFetching" class="min-h-100px flex justify-center items-center">
      <i class="i-svg-spinners:180-ring-with-bg block text-30px color-#3870f0" />
    </div>

    <!-- 加载失败 -->
    <div v-if="isLoadingError" class="min-h-100px flex justify-center items-center cursor-pointer" @click="fetchNextPage()">
      <span class="text-#94a3b8">{{ $t('ga4cH83C0emmMohyWjxN') }}</span>
    </div>
  </div>
</template>

